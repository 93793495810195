import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OpenIcon from '@material-ui/icons/OpenInNew';
import SendIcon from '@material-ui/icons/Send';

import { getEntitiesArray } from '../reducers/entitiesReducer';
import notificationActions from '../containers/general/notification/NotificationsManager/actions';
import popupActions from '../containers/general/popup/PopupsManager/actions';
import cacheActions from '../actions/cacheActions';
import DefaultTable from '../containers/general/conf-table/ConfTable';
import { infoColor, dangerColor } from '../assets/jss/material-dashboard-pro-react';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../assets/scss/react-confirm-alert/react-confirm-alert.css';

const generateEntityTable = ({
  entityPluralString,
  entityActions,
  editorRoutePath,
  operations,
  pathId,
  editOperation,
  openOperation,
  forceExecutionOperation,
  forceExecutionRoutePath,
  openRoutePath,
  ...otherProps
}) => connect(state => ({
  entities: getEntitiesArray(entityPluralString)(state),
}))(class extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    entities: PropTypes.array.isRequired,
  };

  state = {
    id: `${Math.random()}`,
  };

  defaultOperations = [
    {
      title: 'Editar',
      content: <EditIcon style={{fontSize: "18px"}} nativeColor={infoColor} />,
      execute: id => this.props.dispatch(push(`${editorRoutePath}/${id}`)),
    },
    {
      title: 'Excluir',
      content: <CloseIcon style={{fontSize: "18px"}} nativeColor={dangerColor} />,
      execute: id => this.confirmDelete(id),
    },
  ];

  getOperations = () => {
    if (operations) {
      return operations;
    }
  
    const ops = [];

    if (editOperation) {
      ops.push({
        title: 'Editar',
        content: <EditIcon style={{ fontSize: "18px" }} nativeColor={infoColor} />,
        execute: id => this.props.dispatch(push(`${editorRoutePath}/${id}`)),
      });
    }

    if (openOperation) {
      ops.push({
        title: 'Abrir',
        content: <OpenIcon style={{ fontSize: "18px" }} nativeColor={infoColor} />,
        execute: id => this.props.dispatch(push(`${openRoutePath.replace(':id', id)}`)),
      });
    }

    if (forceExecutionOperation) {
      ops.push({
        title: 'Forçar Envio',
        content: <SendIcon style={{ fontSize: "18px" }} nativeColor={infoColor} />,
        execute: id => this.props.dispatch(push(`${forceExecutionRoutePath.replace(':id', id)}`)),
      });
    }
  
    return ops.length > 0 ? ops : this.defaultOperations;
  };

  componentWillMount() {
    this.onMount();
  }

  // fetchEntities = () => this.props.dispatch(entityActions.fetchAll());
  fetchEntities = (id = null) => {
    if (id) {
      return this.props.dispatch(entityActions.fetchAll(id));
    } else {
      return this.props.dispatch(entityActions.fetchAll());
    }
  };

  updateEntity = (id, params) => this.props.dispatch(entityActions.update(id, params));

  deleteEntity = id => this.props.dispatch(entityActions.delete(id));

  onMount = () => {
    this.props.dispatch(cacheActions.cleanCache());
    this.fetchEntities(pathId)
      .catch(this.onFetchFail);
  };
  

  onFieldUpdate = (id, columnName, value) => {
    const params = { [columnName]: value };
    this.updateEntity(id, params)
      .then(this.onUpdateSucceed)
      .catch(this.onUpdateFail);
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirmação de exclusão',
      message: 'Deseja mesmo apagar o registro.',
      buttons: [{
        label: 'Sim',
        onClick: () => this.onRowDelete(id)
      },
      {
        label: 'Não',
        onClick: () => {}
      }]
    });
  }

  onRowDelete = (id) => {
    this.deleteEntity(id)
      .then(this.onDeleteSucceed)
      .catch(this.onDeleteFail);
  };

  onFetchFail = (apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível acessar os dados.';
    const color = 'danger';
    console.log(apiResponse);
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  onUpdateSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. O dado foi atualizado com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
  };

  onUpdateFail = (apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível atualizar o dado.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  onDeleteSucceed = () => {
    const { dispatch } = this.props;
    const message = 'Operação concluída. Os dados foram excluídos com sucesso.';
    const color = 'success';
    dispatch(notificationActions.showNotification(message, color));
  };

  onDeleteFail = (apiResponse) => {
    const { dispatch } = this.props;
    const message = 'Operação falhou. Não foi possível excluir os dados.';
    const color = 'danger';
    dispatch(notificationActions.showNotification(message, color));
    dispatch(popupActions.handleApiResponse(apiResponse));
  };

  render() {
    const { entities } = this.props;
    const { id } = this.state;
    return (
      <>
        <DefaultTable
          id={id}
          dataList={entities}
          operations={this.getOperations()}
          onFieldUpdate={this.onFieldUpdate}
          {...otherProps}
        />
      </>
    );
  }
});

export default generateEntityTable;
