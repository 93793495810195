import employeeActions from '../../../actions/employeeActions';
import contractTypeActions from '../../../actions/contractTypeActions';
import professionActions from '../../../actions/professionActions';
import partnerEnterpriseActions from '../../../actions/partnerEnterpriseActions';
import { getAbility } from '../../../reducers/sessionReducer';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';
import _ from 'lodash';

const clientInputFields = (formValues, dispatch, state) => {
  let fields = [
    {
      label: 'Informações pessoais',
      type: 'accordion',
      fields: [
        {
          name: 'name',
          label: 'Nome',
        },
        {
          name: 'nickname',
          label: 'Apelido',
        },
        {
          name: 'email',
          label: 'E-mail',
        },
        {
          name: 'alternativeEmail',
          label: 'E-mail alternativo',
        },
        {
          name: 'cpf',
          label: 'CPF',
          width: 120,
        },
        {
          name: 'gender',
          label: 'Sexo',
          type: 'select',
          options: ['Masculino', 'Feminino']
        },
        {
          name: 'telephone',
          label: 'Telefone',
          width: 150,
        },
        {
          name: 'alternativeTelephone',
          label: 'Telefone alternativo',
          width: 150,
        },
        {
          name: 'addresses',
          label: 'Endereços',
          type: 'array',
          itemField: {
            label: 'Endereço',
            type: 'group',
            fields: [
              { name: 'name', label: 'Nome', width: 330 },
              { name: 'cep', label: 'CEP', width: 100 },
              { name: 'street', label: 'Logradouro', width: 450 },
              { name: 'number', label: 'Nº', width: 35 },
              { name: 'complement', label: 'Complemento' },
              { name: 'neighborhood', label: 'Bairro', width: 160 },
              { name: 'city', label: 'Cidade', width: 160 },
              { name: 'state', label: 'Estado', type: 'select', options: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'], width: 75 },
              { name: 'country', label: 'País', type: 'select', options: ['Brasil', 'Estados Unidos'], width: 90 },
            ],
          },
        },
        {
          name: 'city',
          label: 'Cidade onde mora',
          width: 220,
        },
        {
          name: 'birthday',
          label: 'Data de nascimento',
          type: 'date',
          width: 150,
        },
        {
          name: 'smoke',
          label: 'Fumante',
          type: 'checkbox'
        },
        {
          name: 'civilStatus',
          label: 'Estado civil',
          type: 'select',
          options: ['Casado', 'Divorciado', 'Solteiro', 'Viúvo', 'Separado'],
          width: 150,
        },
        {
          name: 'dependents',
          label: 'Dependentes',
          type: 'array',
          itemField: {
            label: 'Dependente',
            type: 'group',
            fields: [
              { name: 'affinity', label: 'Parentesco', width: 120 },
              { name: 'name', label: 'Nome', width: 330 },
              { name: 'birthDate', label: 'Data de nascimento', type: 'date', width: 150 },
            ],
          },
        },
      ],
    },
    {
      label: 'Informações profissionais',
      type: 'accordion',
      fields: [
        {
          name: 'professionId',
          label: 'Profissão',
          type: 'object-select',
          meta: { valueKey: 'id', labelKey: 'name' },
          async: {
            fetch: () => dispatch(professionActions.fetchAll()),
            fetchDelay: 500,
            mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.professions), 'name'),
            mapProps: ({ response, ...others }) => ({ ...others, options: response }),
          },
          width: 220,
        },
        {
          name: 'professionRole',
          label: 'Cargo que exerce',
        },
        {
          name: 'workCompany',
          label: 'Companhia onde trabalha',
          width: 220,
        },
        {
          name: 'incomeGenerationStability',
          label: 'Estabilidade na geração de renda',
          type: 'select',
          options: ['Baixa', 'Baixa Moderada', 'Moderada', 'Moderada Alta', 'Alta'],
        },
      ],
    },
    {
      label: 'Informações financeiras',
      type: 'accordion',
      fields: [
        {
          name: 'income',
          label: 'Renda mensal',
          type: 'brCurrency',
          width: 150,
        },
        {
          name: 'monthlyExpenses',
          label: 'Gastos mensais',
          type: 'brCurrency',
          width: 150,
        },
        {
          name: 'savingCapacity',
          label: 'Capacidade de poupança',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'standardLivingValue',
          label: 'Manutenção do padrão de vida',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'privatePensionValue',
          label: 'Previdência Privada (Saldo)',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'otherIncomeSources',
          label: 'Outras fontes de renda',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'percentageExpenses',
          label: 'Percentual de contribuição (Despesa)',
          type: 'percentage',
          width: 200,
        },
        {
          name: 'lifeInsurances',
          label: 'Seguros de vida',
          type: 'array',
          itemField: {
            label: 'Seguro de vida',
            type: 'group',
            fields: [
              { name: 'insuranceCompany', label: 'Seguradora', width: 120 },
              { name: 'product', label: 'Produto', width: 330 },
              { name: 'deadline', label: 'Prazo', type: 'date', width: 150 },
              { name: 'insuredCapital', label: 'Capital Segurado', type: 'brCurrency', width: 200 },
              { name: 'value', label: 'Prêmio', type: 'brCurrency', width: 200 },
            ],
          },
        },
        {
          name: 'pensionTarget',
          label: 'Expectativa de Pensão (INSS/Outros)',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'fgtsBalance',
          label: 'Saldo acumulado de FGTS',
          type: 'brCurrency',
          width: 200,
        },
        {
          name: 'net',
          label: 'NET XP',
          type: 'brCurrency',
          disabled: getAbility(state).cannot('update', 'Client'),
          width: 150,
        },
        {
          name: 'insurancePremium',
          label: 'Prêmio de seguro',
          type: 'brCurrency',
          width: 150,
        },
        {
          name: 'emergencyReserve',
          label: 'Reserva de Emergência',
          type: 'brCurrency',
          width: 200,
        },
      ],
    },
    {
      label: 'Informações comerciais',
      type: 'accordion',
      fields: [
        {
          name: 'relative',
          label: 'Parente',
          type: 'checkbox'
        },
        {
          name: 'advisorId',
          label: 'Assessor responsável',
          notRenderable: getAbility(state).cannot('update', 'Client'),
          type: 'object-select',
          meta: { valueKey: 'id', labelKey: 'name' },
          options: [],
          async: {
            fetch: () => dispatch(employeeActions.fetchAll()),
            fetchDelay: 500,
            mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), 'name'),
            mapProps: ({ response, ...others }) => ({ ...others, options: response }),
          },
          width: 320,
        },
        {
          name: 'segmentation',
          label: 'Segmentação',
          type: 'select',
          options: ['A', 'B', 'C', 'D'],
          width: 120,
        },
        {
          name: 'referral',
          label: 'Indicação',
        },
        {
          name: 'relationshipType',
          label: 'Status',
          type: 'select',
          options: ['Ex-cliente', 'Sem interesse'],
          width: 150,
        },
        {
          name: 'specialRemunerationTables',
          label: 'Tabela de repasses (especial)',
          type: 'array',
          itemField: {
            label: 'Repasse',
            type: 'group',
            fields: [
              {
                name: 'enterpriseId',
                label: 'Fornecedor',
                type: 'object-select',
                meta: { valueKey: 'id', labelKey: 'name' },
                options: [],
                async: {
                  fetch: () => dispatch(partnerEnterpriseActions.fetchAll()),
                  fetchDelay: 500,
                  mapSuccessfulResult: res => sortObjectsArrayByProperty(Object.values(res.data.entities.partnerEnterprises), 'name'),
                  mapProps: ({ response, ...others }) => ({ ...others, options: response }),
                },
                width: 180,
              },
              {
                name: 'percentageOfTransfer',
                label: '% transf',
                type: 'percentage',
                width: 80,
                disabled: getAbility(state).cannot('update', 'Client'),
              },
            ],
          },
        },
      ],
    },
    {
      label: 'Objetivos',
      type: 'accordion',
      fields: [
        {
          name: 'objectives',
          label: null,
          type: 'array',
          itemField: {
            label: 'Objetivo',
            type: 'group',
            fields: [
              { name: 'description', label: 'Descrição', width: 400 },
              { name: 'value', label: 'Valor', type: 'brCurrency', width: 400 },
              { name: 'startDate', label: 'Início do Objetivo', type: 'date', width: 200 },
              { name: 'endDate', label: 'Fim do Objetivo', type: 'date', width: 200 },
              { name: 'objectiveType', label: 'Tipo', type: 'select', options: ['Pessoal', 'Familiar'], width: 200 },
              { name: 'realInterestRate', label: 'Juro Real', type: 'select', options: ['3', '4', '5'], width: 200 },
              { name: 'priority', label: 'Prioridade', type: 'checkbox', width: 200 },
              { name: 'monthRecurance', label: 'Recorrente', type: 'checkbox', width: 200 },
            ],
          },
        },
      ]
    },
    {
      label: 'Informações estratégicas',
      type: 'accordion',
      fields: [
        {
          name: 'description',
          label: 'Informações importantes',
        },
        {
          name: 'investmentProfile',
          label: 'Perfil de Investidor XP',
          type: 'select',
          options: ['Conservador', 'Conservador moderado', 'Moderado', 'Moderado agressivo', 'Agressivo'],
          width: 240,
        },
        {
          name: 'ciProfile',
          label: 'Perfil de Investidor Confiança',
          type: 'select',
          options: ['Conservador', 'Conservador moderado', 'Moderado', 'Moderado agressivo', 'Agressivo'],
          width: 240,
        },
        {
          name: 'accreditedInvestor',
          label: 'É investidor qualificado?',
          type: 'checkbox',
        },
        {
          name: 'relationshipStartDate',
          label: 'Inicio do relacionamento',
          type: 'date',
        },
      ],
    },
    {
      label: 'Informações dos fornecedores',
      type: 'accordion',
      fields: [
        {
          name: 'xpCode',
          label: 'Código XP',
          width: 200,
        },
        {
          name: 'xpDigitVerifier',
          label: 'Verificador Bolsa XP',
          width: 200,
          type: 'select',
          options: ['0','1','2','3','4','5','6','7','8','9']
        },
        {
          name: 'btgCode',
          label: 'Código BTG',
          width: 200,
        },
        {
          name: 'btgDigitVerifier',
          label: 'Verificador Bolsa BTG',
          width: 200,
          type: 'select',
          options: ['0','1','2','3','4','5','6','7','8','9']
        },
        {
          name: 'mongeralCode',
          label: 'Matrícula Mongeral',
          disabled: getAbility(state).cannot('update', 'Client'),
          width: 200,
        },
      ],
    },
    {
      label: 'Dívidas e Financiamentos',
      type: 'accordion',
      fields: [
        {
          name: 'debtsFinancings',
          label: null,
          type: 'array',
          itemField: {
            label: 'Dívidas e Financiamentos',
            type: 'group',
            fields: [
              { name: 'description', label: 'Descrição', width: 330 },
              { name: 'outstandingBalance', label: 'Saldo Devedor', type: 'brCurrency', width: 200 },
              { name: 'remainingMonths', label: 'Prazo restante', type: 'number', width: 200 },
              { name: 'annualCetRate', label: 'Taxa CET', type: 'percentage', width: 200 },
              { name: 'sacPrice', label: 'SAC/Price', type: 'select', width: 200, options: ['SAC','Price'] },
              { name: 'month_recurance', label: 'Observação', width: 200 },
            ],
          },
        },
      ]
    },
    {
      label: 'Patrimônio',
      type: 'accordion',
      fields: [
        {
          name: 'patrimonies',
          label: null,
          type: 'array',
          itemField: {
            label: 'Patrimônio',
            type: 'group',
            fields: [
              { name: 'description', label: 'Descrição', width: 330 },
              { name: 'institution', label: 'Instituição', width: 330 },
              { name: 'value', label: 'Valor', type: 'brCurrency', width: 200 },
              { name: 'lastTwelveMothsTax', label: 'Taxa nos ultimos 12 meses (% do CDI)', type: 'percentage', width: 330 },
              { name: 'observation', label: 'Observação', width: 200 },
              { name: 'liquidable', label: 'Liquidável?', type: 'checkbox', width: 200 },
            ],
          },
        },
      ]
    },
  ]

  const urlParams = new URLSearchParams(window.location.search)
  if(urlParams.has('client_ids')) {
    const permittedGroups = ['Informações comerciais', 'Informações estratégicas'];
    const permittedFields = ['advisorId', 'segmentation', 'relationshipType', 'funnelStage', 'contractTypeId', 'startDate', 'investmentProfile']
    fields = _.filter(fields, (group) => permittedGroups.includes(group.label));

    fields.forEach((group) => {
      group.fields = _.filter(group.fields, (field) => {
        if(field.type === 'array') {
          let groupField = _.filter(field.itemField.fields, (field) => permittedFields.includes(field.name));
          field.itemField.fields = groupField;
          return groupField.length !== 0
        } else {
          return permittedFields.includes(field.name)
        }
      });
    })
  } else if (!window.location.pathname.includes('/my-client-edition/')) {
    const blockedFields = ['relationshipType']
    fields.forEach((group) => {
      group.fields = _.filter(group.fields, (field) => {
        if(field.type === 'array') {
          let groupField = _.filter(field.itemField.fields, (field) => !blockedFields.includes(field.name));
          field.itemField.fields = groupField;
          return groupField.length !== 0
        } else {
          return !blockedFields.includes(field.name)
        }
      });
    })

  }

  return fields;
}

export default clientInputFields;
