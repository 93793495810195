import generateBasicEntityActions from './generateBasicEntityActions';

const basicEntityActions = generateBasicEntityActions('userTriggers', 'userTrigger', null, true, 'trigger_types/{id}/user_triggers');

const userTriggerActions = {
  updateAll: (params) => ({
    API_CALL: {
      httpMethod: 'PUT',
      endpoint: '/user_triggers/update_all/',
      params: params,
    },
  }),

  sendAll: (params) => ({
    API_CALL: {
      httpMethod: 'POST',
      endpoint: '/user_triggers/force_execution_all/',
      params: params,
    },
  }),

  ...basicEntityActions,
};

export default userTriggerActions;
