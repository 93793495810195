import React, { useState, useEffect } from 'react';

import userTriggerActions from '../../../actions/userTriggerActions';
import userTriggerTableColumns, { showSendAllConfirmationAlert } from './userTriggerTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';
import ciApi from '../../../services/ciApi';
import { userTriggerSelectionOperations, showUpdateAllConfirmationAlert } from './userTriggerTableColumns';

const UserTriggersTable = ({ pathId }) => {
  const [columns, setColumns] = useState(userTriggerTableColumns); // Inicializa com colunas padrão
  const [selectionOperations, setSelectionOperations] = useState(userTriggerSelectionOperations); // Inicializa com selectionOperations padrão

  const handleAdditionalColumns = async () => {
    try {
      // Usando ciApi.call para fazer a requisição
      const response = await ciApi.call({
        httpMethod: 'get',
        endpoint: `/trigger_types/${pathId}/additional_columns`
      });

      if (!response.isSuccess) {
        throw new Error(`Erro ao buscar colunas adicionais: ${response.data}`);
      }

      const additionalColumns = response.data;
      return additionalColumns;
    } catch (error) {
      console.error('Erro ao buscar colunas adicionais:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchColumns = async () => {
      const response = await handleAdditionalColumns();
      const additionalColumns = response.additionalColumns;

      // Inicializa o array de colunas adicionais
      const columnsToAdd = [];
      const selectionOperationsToAdd = [];

      const forceExecutionOperation = {
        label: "Forçar Envio",
        action: (selectedUserTriggers) => {
          const params = {
            user_trigger_ids: selectedUserTriggers
              .filter((item) => item != null)
              .map((item) => item.id)
          };
      
          showSendAllConfirmationAlert("Forçar envio de notificação agora para os clientes selecionados?", params);
        },
        renderCondition: (selectedClients) => selectedClients.length > 0,
      };

      // Itera sobre as colunas adicionais e cria os objetos de coluna
      Object.keys(additionalColumns).forEach((key) => {
        const values = additionalColumns[key];

        const columToAdd = {
          name: key,
          title: values.title,
          editable: values.editable,
          width: values.width,
          type: values.type
        };

        const selectionOperationToAdd = [
          {
            label: `Habilitar ${values.title}`,
            field: values.snakeCase,
            value: true,
            confirmationMessage: `Habilitar ${values.title} para os clientes selecionados?`,
          },
          {
            label: `Desabilitar ${values.title}`,
            field: values.snakeCase,
            value: false,
            confirmationMessage: `Desabilitar ${values.title} para os clientes selecionados?`,
          }
        ].map((operation) => ({
          label: operation.label,
          action: (selectedUserTriggers) => {
            const params = {
              user_trigger_ids: selectedUserTriggers
                .filter((item) => item != null)
                .map((item) => item.id),
              field: operation.field,
              value: operation.value,
            };
        
            showUpdateAllConfirmationAlert(operation.confirmationMessage, params);
          },
          renderCondition: (selectedClients) => selectedClients.length > 0,
        }));

        columnsToAdd.push(columToAdd);
        selectionOperationsToAdd.push(...selectionOperationToAdd);
      });

      // Combina as colunas padrão com as novas colunas
      const updatedColumns = [...userTriggerTableColumns, ...columnsToAdd];
      const updatedSelectionOperations = [forceExecutionOperation, ...userTriggerSelectionOperations, ...selectionOperationsToAdd];
      setColumns(updatedColumns);
      setSelectionOperations(updatedSelectionOperations);
    };

    fetchColumns();
  }, [pathId]);

  const Component = generateEntityTable({
    entityPluralString: 'userTriggers',
    entityActions: userTriggerActions,
    editorRoutePath: '/user-trigger-edition',
    columns,
    pathId,
    editOperation: true,
    enableSelection: true,
    forceExecutionOperation: false,
    forceExecutionRoutePath: '/user-trigger-test',
    selectionOperations,
    operations: []
  });

  return <Component />;
};

export default UserTriggersTable;
